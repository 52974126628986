<template>
  <div class="header">
    <h1>{{ this.$t("mypage.order_history") }}</h1>
    <ul class="path">
      <li>
        <router-link to="/mypage">{{ this.$t("mypage.mypage") }}</router-link>
      </li>
      <li class="current">
        <router-link to="/mypage/order">{{
          this.$t("mypage.order_history")
        }}</router-link>
      </li>
    </ul>
  </div>
  <div class="body">
    <div class="search_wrap">
      <div class="status">
        <div
          class="type"
          :class="{ selected: this.search_status == 'ORDER' }"
          @click="this.click_search_status('ORDER')"
        >
          <h3>{{ this.$t("mypage.ordered") }}</h3>
          <img src="@/assets/img/icon_pay_ok.svg" alt="Order Complete" />
          <div class="number">
            {{ this.common.number_format(this.count_order) }}
          </div>
        </div>

        <div
          class="type"
          :class="{ selected: this.search_status == 'PREPARE' }"
          @click="this.click_search_status('PREPARE')"
        >
          <h3>{{ this.$t("mypage.preparing") }}</h3>
          <img src="@/assets/img/icon_gift.svg" alt="Preparing" />
          <div class="number">
            {{ this.common.number_format(this.count_prepare) }}
          </div>
        </div>

        <div
          class="type"
          :class="{ selected: this.search_status == 'SHIPPED' }"
          @click="this.click_search_status('SHIPPED')"
        >
          <h3>{{ this.$t("mypage.shipped") }}</h3>
          <img src="@/assets/img/icon_truck.svg" alt="Shipped" />
          <div class="number">
            {{ this.common.number_format(this.count_shipped) }}
          </div>
        </div>

        <div
          class="type"
          :class="{ selected: this.search_status == 'DELIVERED' }"
          @click="this.click_search_status('DELIVERED')"
        >
          <h3>{{ this.$t("mypage.delivered") }}</h3>
          <img src="@/assets/img/icon_delivered.svg" alt="Delivered" />
          <div class="number">
            {{ this.common.number_format(this.count_delivered) }}
          </div>
        </div>

        <div
          class="type"
          :class="{ selected: this.search_status == 'CANCEL' }"
          @click="this.click_search_status('CANCEL')"
        >
          <h3>{{ this.$t("mypage.return") }}</h3>
          <img src="@/assets/img/icon_return.svg" alt="Return" />
          <div class="number">
            {{ this.common.number_format(this.count_cancel) }}
          </div>
        </div>
      </div>

      <div class="date">
        <input type="date" v-model="this.search_order_start_date" /><span
          >-</span
        ><input type="date" v-model="this.search_order_end_date" />
        <button class="button middle red search" @click="this.click_search">
          {{ this.$t("common.search") }}
        </button>
      </div>
    </div>

    <ul class="order_list">
      <li class="empty" v-if="this.order_data.length <= 0">
        {{ this.$t("common.no_data") }}
      </li>
      <li v-for="(value, index) in this.order_data" :key="index">
        <div
          class="image"
          :style="{
            backgroundImage: 'url(' + value.order_product_image_url + ')',
          }"
        ></div>
        <div class="info">
          <div class="date">
            {{
              this.common.date_long_format(
                this.common.date_parse(value.order_date)
              )
            }}
          </div>
          <div class="name">
            <router-link
              :to="{ path: '/mypage/order/detail/' + value.order_id }"
              >{{ value.order_name }}</router-link
            >
          </div>
          <div class="price">
            {{
              this.common.currency_format(value.payment_price, value.currency)
            }}
          </div>
        </div>
        <div class="right">
          <div class="status">
            {{ this.convert_status_text(value.order_status) }}
          </div>
          <div
            class="request"
            v-if="
              value.order_status == 'ORDER' &&
              typeof value.cancel_request_status != 'undefined' &&
              value.cancel_request_status != null &&
              value.cancel_request_status != null
            "
          >
            {{
              this.convert_request_cancel_status_text(
                value.cancel_request_status
              )
            }}
          </div>
        </div>
      </li>
    </ul>

    <button
      class="button outline small sharp compact more"
      v-if="this.order_data.length && !this.data_fully_loaded"
      @click="load_order_data"
    >
      {{ this.$t("common.load_more") }}
    </button>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "MyOrder",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.myorder"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.myorder"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_page: 1,
      search_status: "",
      search_order_start_date: "",
      search_order_end_date: "",
      order_data: [],

      count_order: 0,
      count_prepare: 0,
      count_shipped: 0,
      count_delivered: 0,
      count_cancel: 0,
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_order_data();
    this.load_order_status_data();
  },
  methods: {
    load_order_data: function () {
      if (!this.storage.is_logged_in()) return;

      // 데이터가 이미 다 로드되었으면 더이상 진행안함.
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/order/list",
          {
            user_id: self.storage.get_user_id(),
            order_status: this.search_status,
            order_start_date: this.search_order_start_date,
            order_end_date: this.search_order_end_date,
            page: this.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            this.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          let data = response.data.data;

          // 처음 로드할 경우 데이터 초기화
          if (self.search_page <= 1) {
            self.order_data = [];
          }

          for (let i = 0; i < data.length; i++) {
            let order_name = "";
            let order_product_image_url = "";
            if (data[i].order_product.length >= 1) {
              order_name = data[i].order_product[0].product_name;

              let str_images = data[i].order_product[0].product_images;
              let arr_images = str_images.split("|");

              if (arr_images.length >= 1) {
                order_product_image_url =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/product/" +
                  data[i].order_product[0].product_id.toString() +
                  "/" +
                  arr_images[0];
              }
            }

            if (data[i].order_product.length > 1) {
              order_name +=
                " " +
                self.$t("order.order_product_and") +
                (data[i].order_product.length - 1).toString() +
                " " +
                self.$t("order.order_product_count");
            }

            data[i].order_name = order_name;
            data[i].order_product_image_url = order_product_image_url;

            self.order_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    },

    load_order_status_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/order/count/status",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          let data = response.data.data;

          self.count_order = data.ORDER;
          self.count_prepare = data.PREPARE;
          self.count_shipped = data.SHIPPED;
          self.count_delivered = data.DELIVERED;
          self.count_cancel = data.CANCEL;
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    },

    click_search_status: function (type) {
      if (this.search_status == type) {
        this.search_status = "";
      } else {
        this.search_status = type;
      }

      this.search_order_start_date = "";
      this.search_order_end_date = "";

      this.search_page = 1;
      this.data_fully_loaded = false;

      this.load_order_data();
    },

    // 검색 버튼 이벤트 핸들러
    click_search: function () {
      this.search_page = 1;
      this.data_fully_loaded = false;

      this.load_order_data();
    },

    convert_status_text: function (status) {
      if (status.trim().toUpperCase() == "ORDER") {
        return this.$t("mypage.ordered");
      } else if (status.trim().toUpperCase() == "PREPARE") {
        return this.$t("mypage.preparing");
      } else if (status.trim().toUpperCase() == "SHIPPED") {
        return this.$t("mypage.shipped");
      } else if (status.trim().toUpperCase() == "DELIVERED") {
        return this.$t("mypage.delivered");
      } else if (status.trim().toUpperCase() == "CANCEL") {
        return this.$t("mypage.return");
      }

      return "";
    },

    // 취소 신청 상태 텍스트
    convert_request_cancel_status_text: function (status) {
      switch (status) {
        case "REQUEST":
          return this.$t("mypage.status_request_cancel");
        case "COMPLETE":
          return this.$t("mypage.status_complete_cancel");
        case "REJECT":
          return this.$t("mypage.status_reject_cancel");
      }

      return "";
    }, //-- convert_request_cancel_status_text
  },
};
</script>

<style scoped>
.body {
}
.body > .search_wrap {
  padding: 1.2rem 1.6rem;
  margin: 0 0 1rem 0;
  border-radius: 0.8rem;
  background: #f4f4f4;
}
.body > .search_wrap > .status {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}
.body > .search_wrap > .status > .type {
  width: 20%;
  padding: 0.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  user-select: none;
}
.body > .search_wrap > .status > .type.selected {
  background: #dedede;
}

@media (hover: hover) and (pointer: fine) {
  .body > .search_wrap > .status > .type:hover {
    background: #dedede;
  }
}

.body > .search_wrap > .status > .type > h3 {
  font-size: 1.1rem;
  font-weight: 400;

  padding: 0;
  margin: 0 0 0.8rem 0;
}
.body > .search_wrap > .status > .type > img {
  height: 2.6rem;
}
.body > .search_wrap > .status > .type > .number {
  font-size: 2rem;
  font-weight: 300;
}

.body > .search_wrap > .date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0 0;
}
.body > .search_wrap > .date > input[type="date"] {
  width: 35%;
}
.body > .search_wrap > .date > span {
  margin: 0 1rem;
}
.body > .search_wrap > .date > button.button {
  letter-spacing: 0;
  width: 20%;
  margin-left: 1rem;
  font-size: 1rem;
  padding: 0.8rem;
}

.body > .order_list {
  list-style-type: none;
  margin: 1rem 0 0 0;
  padding: 0;
}
.body > .order_list > li {
  position: relative;
  display: block;
  padding: 1rem 0;
  margin: 0;
  border-bottom: 1px solid #dedede;
}
.body > .order_list > li.empty {
  padding: 4rem 0;
  text-align: center;
}
.body > .order_list > li > .image {
  display: inline-block;
  vertical-align: middle;
  width: 96px;
  height: 96px;
  background: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.body > .order_list > li > .info {
  display: inline-block;
  vertical-align: middle;

  width: calc(100% - 96px - 1rem - 96px);
  margin: 0 0 0 1rem;
}
.body > .order_list > li > .info > .date {
  font-weight: 300;
  color: #808080;
  margin: 0 0 0.4rem 0;
}
.body > .order_list > li > .info > .name {
  font-size: 1.1rem;
  font-weight: 700;
  color: #000;
  margin: 0 0 0.4rem 0;
}
.body > .order_list > li > .info > .name > a {
  color: #000;
  text-decoration: none;
}
.body > .order_list > li > .info > .name > a:hover {
  text-decoration: underline;
}
.body > .order_list > li > .info > .price {
  font-size: 1.2rem;
}
.body > .order_list > li > .right {
  position: absolute;
  right: 1rem;
  bottom: 50%;
  transform: translate(0, 50%);

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.body > .order_list > li > .right > .status {
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
  border: 1px solid #000;
  border-radius: 3rem;
}
.body > .order_list > li > .right > .request {
  font-size: 0.7rem;
  font-weight: 700;

  color: #fff;
  background: #333;
  padding: 0.4rem 0.8rem;
  margin: 0.4rem 0 0 0;
  border-radius: 3rem;
}

.body > button.more {
  display: block;
  margin: 2rem auto 0 auto;
}

@media (max-width: 600px) {
  .body > .search_wrap {
    padding: 1rem;
  }
  .body > .search_wrap > .status > .type {
    padding: 0.4rem;
  }
  .body > .search_wrap > .status > .type > h3 {
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0 0 0.8rem 0;
  }
  .body > .search_wrap > .status > .type > img {
    height: 1.8rem;
  }
  .body > .search_wrap > .status > .type > .number {
    font-size: 1.5rem;
    font-weight: 300;
  }
}

@media (max-width: 400px) {
  .body > .search_wrap {
    padding: 0.5rem;
  }
  .body > .search_wrap > .status > .type {
    padding: 0.2rem;
  }
  .body > .search_wrap > .status > .type > .number {
    font-size: 1rem;
  }
  .body > .search_wrap > .date {
    margin-top: 0.6rem;
  }
}
</style>
